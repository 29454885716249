*::-webkit-scrollbar {
    background-color: transparent;
    height: 5px;
    width: 5px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c5c5c5;
}

.index-card {
    width: 200px;
    height: 100px;
    padding: 10px 10px 10px 10px;
    margin: 0 1.5rem 0 1.5rem;
    background-color: #1F1F1F;
    border-radius: 10px;
    text-align: center;
    scroll-snap-align: start;
}

.index-card-info > p {
    margin-bottom: 0;
    text-wrap: nowrap;
}

.index-card .index-card-chart,
.index-card .index-card-info {
    flex: auto;
}

.index-card .index-card-chart {
    height: 50px;
    width: 200px;
    margin-top: 15px;
    overflow: auto;
}

.filter-bar .dropdown-select {
    background-color: #1f1f1f;
    border-color: transparent;
    height: 35px;
    cursor: pointer;
    margin: 0 5px 0 5px;
    > option {
        background-color: #1f1f1f;
    }
}
.filter-bar .element {
    margin: 0 5px 0 10px;
}
.filter-bar .element.icon {
    margin-top: 7px;
}
.companies-list .dropdown-select:hover {
    border-color: #606060;
}

.dropdown-select option:hover {
    background-color: transparent;
    cursor: pointer;
}

.table-header th {
    color: white;
}

.tab-list button {
    font-size: 18px;
}

.sectors-heatmap .time-frame >label {
    color: white;
    margin: 0 5px 0 5px;
    border-color: transparent;
    background-color: #393939;
    border-radius: 5px;
    width: 70px;
}

.flags-dropdown button i {
    margin-right: 10px;
}

.macro-economic-charts .chart {
    color: white;
    margin: 15px;
    padding: 10px;
    border-radius: 15px;
    background-color: #1f1f1f;
    >span {
        font-weight: bold;
    }
}
.technical-analysis .time-frame .btn {
    padding: 2px 10px 2px 10px;
}
.technical-analysis .time-frame {
    margin-right: 15px;
}
.technical-analysis .time-frame >label {
    height: 25px;
    font-size: 12px;
    font-weight: bold;
    background-color: #3c3c3c;
    border-color: transparent;
    color: white;
    width: auto;
}
.horizontal-swiper .swiper-button-next,
.horizontal-swiper .swiper-button-prev {
    color: black;
    font-weight: bold;
    background-color: #ffce30;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}
.horizontal-swiper .swiper-button-next::after,
.horizontal-swiper .swiper-button-prev::after {
    font-size: 20px;
}
.horizontal-swiper .swiper-button-disabled {
    display: none !important;
}

.vertical-swiper .swiper-button-next,
.vertical-swiper .swiper-button-prev {
    color: black;
    font-weight: bold;
    background-color: #ffce30;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.vertical-swiper .swiper-button-next {
    position: sticky;
    left: 50%;
    transform: translateX(-50%);
}
.vertical-swiper .swiper-button-prev {
    top: 10px; /* Adjust the distance from the bottom */
    left: 50%;
    transform: translateX(-50%);
}
.vertical-swiper .swiper-button-next::after,
.vertical-swiper .swiper-button-prev::after {
    font-size: 20px;
    rotate: 90deg;
}
.vertical-swiper .swiper-button-disabled {
    display: none !important;
}
